import React from "react"
import ListEvents from "../../components/events/ListEvents"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

export default function EventsIndexPage() {
  return (
    <Layout>
      <SEO title="Evenemang" />
      <div className="page-inner xl:bg-thirteenth/20  2xl:mt-8">
        <ListEvents lang="sv" />
      </div>
      <div className="page-inner"></div>
    </Layout>
  )
}
